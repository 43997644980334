<template>
  <b-modal
    visible
    scrollable
    centered
    size="xl"
    :title="'Новый полис'"
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="policies-form-fields">
      <div>
        <div class="crm-form-field d-flex justify-content-between">
          <div class="crm-form-block">
            <b-form-group>
              <p class="crm-label">
                Фамилия <span class="required ml-1">*</span>
              </p>
              <b-form-input
                v-model="$v.policy.lastName.$model"
                type="text"
                class="crm-form-field"
                :state="validateState('lastName')"
                trim
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="crm-form-block ml-4">
            <b-form-group>
              <p class="crm-label">
                Имя <span class="required ml-1">*</span>
              </p>
              <b-form-input
                v-model="$v.policy.firstName.$model"
                type="text"
                class="crm-form-field"
                :state="validateState('firstName')"
                trim
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="clinics-form-fields">
        <div class="crm-form-block w-50">
          <b-form-group>
            <p class="crm-label">
              Отчество
            </p>
            <b-form-input
              v-model="$v.policy.middleName.$model"
              type="text"
              class="crm-form-field"
              trim
            />
          </b-form-group>
        </div>
        <!-- <div class="crm-form-block w-50">
          <p class="crm-label required">
            Пол
          </p>
          <b-form-select
            v-model="$v.policy.gender.$model"
            :options="genderHuman"
            :state="validateState('gender')"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >
            Данное поле обязательно*
          </b-form-invalid-feedback>
        </div> -->
        <div class="crm-form-block w-25 ml-4">
          <p class="crm-label">
            Дата рождения <span class="required ml-1">*</span>
          </p>
          <base-date-picker
            v-model="$v.policy.birthday.$model"
            :error="$v.policy.birthday.$error"
            :errors="errorsValidation.birthday"
            fluid
            class="crm-datepicker"
          />
        </div>
        <div class="crm-form-block w-25 ml-2">
          <p class="crm-label">
            Номер телефона
          </p>
          <b-form-input
            v-model.trim="$v.policy.phoneNumber.$model"
            v-mask="phoneMaskString"
            :maxlength="phoneNumberLimit"
            class="crm-form-field"
            placeholder="Номер телефона"
          />
          <transition name="fade">
            <div
              v-if="$v.policy.phoneNumber.$model && !isPhoneNumberValid"
              class="phone-container-text"
            >
              Номер должен начинаться на +7 или 8
            </div>
          </transition>
        </div>
      </div>
      <div class="clinics-form-fields">
        <div class="crm-form-field w-50">
          <div class="crm-form-block">
            <p class="crm-label">
              Страховая компания <span class="required ml-1">*</span>
            </p>
            <b-form-select
              v-model="$v.policy.companyName.$model"
              :options="insuranceCompanies"
              value-field="title"
              text-field="title"
              class="select-specialization"
              placeholder="Выберите страховую компанию"
              label="title"
              :state="validateState('companyName')"
              @input="changeCompany"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="crm-form-field w-50">
          <div class="crm-form-block">
            <p class="crm-label">
              Страховая программа <span class="required ml-1">*</span>
            </p>
            <b-form-select
              v-model="$v.policy.programName.$model"
              :options="filteredInsurancePrograms"
              value-field="name"
              text-field="name"
              class="select-specialization"
              placeholder="Выберите страховую программу"
              label="name"
              :state="validateState('programName')"
              @input="changeProgram"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
      <div class="clinics-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-label">
              Подпрограмма прикрепления <span class="required ml-1">*</span>
            </p>
            <b-form-select
              v-model="$v.policy.subPrograms.$model"
              :options="filteredInsuranceSubprograms"
              value-field="id"
              text-field="name"
              class="select-specialization"
              placeholder="Выберите страховую подпрограмму"
              label="name"
              trim
              :state="validateState('subPrograms')"
              @input="onSubprogramChange($event)"
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="crm-form-field">
          <div class="crm-form-block">
            <b-form-group>
              <p class="crm-label">
                Серийный номер полиса <span class="required ml-1">*</span>
              </p>
              <b-form-input
                v-model="$v.policy.policyId.$model"
                type="text"
                class="crm-form-field"
                :state="validateState('policyId')"
                trim
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                Данное поле обязательно*
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="clinics-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-field">
            <div
              class="crm-form-block"
              :style="{
                minWidth: '150px',
              }"
            >
              <p class="crm-label">
                Дата начала <span class="required ml-1">*</span>
              </p>
              <base-date-picker
                v-model="$v.policy.startDate.$model"
                :error="$v.policy.startDate.$error"
                :errors="errorsValidation.startDate"
                :max-date="maxPolicyEditData"
                fluid
                class="crm-datepicker"
              />
            </div>
          </div>
          <div class="crm-form-field">
            <div
              class="crm-form-block"
              :style="{
                minWidth: '150px',
              }"
            >
              <p class="crm-label">
                Дата окончания <span class="required ml-1">*</span>
              </p>
              <base-date-picker
                v-model="$v.policy.endDate.$model"
                :error="$v.policy.endDate.$error"
                :errors="errorsValidation.endDate"
                :min-date="minPolicyEditDateTo"
                fluid
                class="crm-datepicker"
              />
            </div>
          </div>
        </div>
        <div class="crm-form-field">
          <div class="crm-form-field">
            <div
              class="crm-form-block"
              :style="{
                minWidth: '150px',
              }"
            >
              <p class="crm-label">
                Дата покупки
              </p>
              <base-date-picker
                v-model="policy.buyDate"
                :max-date="maxDate"
                fluid
                class="crm-datepicker"
              />
            </div>
          </div>
          <base-input
            v-model.trim="policy.ensurerName"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
            }"
            class="crm-form-field"
            label="Страхователь"
            placeholder="Введите страхователя"
          />
        </div>
      </div>
    </div>
    <div class="">
      <b-form-checkbox
        v-model="policy.isTest"
      >
        Тестовый полис
      </b-form-checkbox>
    </div>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="button_transparent button-remove-policy"
        @click="onSavePolicy()"
      >
        Добавить
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import {
  BaseInput,
  BaseDatePicker,
} from '@/components/base';
import { formatISO } from '@evd3v/date-fns';

export default {
  name: 'PolicyCreateModal',
  components: {
    BaseInput,
    BaseDatePicker,
  },
  mixins: [validationMixin],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      policy: {
        lastName: null,
        firstName: null,
        middleName: null,
        // gender: null,
        birthday: null,
        companyName: null,
        policyId: null,
        subPrograms: null,
        programName: null,
        startDate: null,
        endDate: null,
        phoneNumber: '',
        isTest: false,
      },
      birthday: null,
      maxDate: new Date(),
      insurancePrograms: [],
      insuranceSubprograms: [],
      subprogramDetailData: null,
      isOpen: true,
      // genderHuman: [
      //   { value: null, text: 'Укажите пол', disabled: true },
      //   { value: 'male', text: 'Мужской' },
      //   { value: 'female', text: 'Женский' },
      // ],
    };
  },
  validations: {
    policy: {
      lastName: { required },
      firstName: { required },
      middleName: {},
      phoneNumber: {},
      // gender: { required },
      birthday: { required },
      endInputDate: { required },
      companyName: { required },
      policyId: { required },
      subPrograms: { required },
      programName: { required },
      startDate: { required },
      endDate: { required },
    },
    // birthday: { required },
    // startInputDate: { required },
    // endInputDate: { required },
  },
  computed: {
    insuranceCompanies() {
      const companies = this.$store.state.Patients.insuranceCompanies.map(({ company }) => (company));
      return companies.filter((item) => !item.isHidden);
    },
    filteredInsurancePrograms() {
      return this.insurancePrograms.filter((item) => !item.isHidden);
    },
    filteredInsuranceSubprograms() {
      return this.insuranceSubprograms.filter((item) => !item.isHidden);
    },
    isPhoneNumberValid() {
      return this.policy.phoneNumber.startsWith('+7') || this.policy.phoneNumber.startsWith('8');
    },
    phoneMaskString() {
      return this.policy.phoneNumber.startsWith('8') ? '#(###) ###-##-##' : '+#(###) ###-##-##';
    },
    phoneNumberLimit() {
      return this.policy.phoneNumber.startsWith('8') ? 16 : 17;
    },
    errorsValidation() {
      const errors = {};

      errors.birthday = [];
      if (!this.$v.policy.birthday.required) {
        errors.birthday.push('Поле не может быть пустым');
      }
      errors.startDate = [];
      if (!this.$v.policy.startDate.required) {
        errors.startDate.push('Поле не может быть пустым');
      }
      errors.endDate = [];
      if (!this.$v.policy.endDate.required) {
        errors.endDate.push('Поле не может быть пустым');
      }
      return errors;
    },
    minPolicyEditDateTo() {
      return this.policy.startDate || null;
    },
    maxPolicyEditData() {
      return this.policy.endDate || new Date();
    },
  },
  async created() {
    await this.getInsuranceCompanies();
    // await this.setPolicyEditData();
  },
  methods: {
    // TODO - не выбирается страховая программа и далее - [fix]
    changeCompany() {
      this.policy.programName = null;
      this.policy.subprogramId = null;
      this.policy.subPrograms = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.policy.subprogramId = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    onSubprogramChange(subprogramId) {
      this.subprogramDetailData = null;
      this.isSubprogramDetailOpen = false;
      if (!subprogramId) return;

      const subprogram = this.insuranceSubprograms.filter((subprog) => subprog.id === subprogramId)[0];
      this.policy.description = subprogram.name;
      this.policy.subProgramV2Id = subprogram.id;
    },
    setInsuranceEntities() {
      if (!this.policy.companyName) return;
      const companyObject = this.$store.state.Patients.insuranceCompanies
        .filter(({ company: { title } }) => title === this.policy.companyName)[0];

      const programs = companyObject.programsWithSubprograms.map(({ program }) => (program));
      this.insurancePrograms = programs;

      if (!this.policy.programName) return;
      const programObject = companyObject.programsWithSubprograms.filter(({ program: { name } }) => name === this.policy.programName)[0];
      this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name, isHidden }) => ({ id: subprogramId, name, isHidden }));
    },
    // setPolicyEditData() {
    //   console.log(this.policy);
    //   this.policy = {
    //     ...this.policy.item,
    //     startDate: this.currentPolicyData.item.startDate ? parseISO(this.currentPolicyData.item.startDate) : null,
    //     endDate: this.currentPolicyData.item.endDate ? parseISO(this.currentPolicyData.item.endDate) : null,
    //   };
    //   this.startInputDate = this.getFormatDate(this.policy.startDate, 'yyyy-MM-dd');
    //   this.endInputDate = this.getFormatDate(this.policy.endDate, 'yyyy-MM-dd');
    //
    //   this.setInsuranceEntities();
    // },
    async getInsuranceCompanies() {
      const insuranceCompanies = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
      this.$store.commit(this.$types.INSURANCE_COMPANIES_SET, insuranceCompanies);
    },
    passwordDisplay() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
      this.passwordShow = !this.passwordShow;
    },
    async onFetch() {
      await this.$store.dispatch(this.$types.GET_USERS);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.policy[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.policy.companyName.$touch();
      this.$v.policy.policyId.$touch();
      this.$v.policy.subPrograms.$touch();
      this.$v.policy.programName.$touch();
      this.$v.policy.endDate.$touch();
      this.$v.policy.startDate.$touch();
      this.$v.policy.lastName.$touch();
      this.$v.policy.firstName.$touch();
      // this.$v.policy.gender.$touch();
      this.$v.policy.birthday.$touch();
      if (this.$v.policy.companyName.$anyError
        || this.$v.policy.policyId.$anyError
        || this.$v.policy.subPrograms.$anyError
        || this.$v.policy.programName.$anyError
        || this.$v.policy.startDate.$anyError
        || this.$v.policy.endDate.$anyError
        || this.$v.policy.lastName.$anyError
        || this.$v.policy.firstName.$anyError
        // || this.$v.policy.gender.$anyError
        || this.$v.policy.birthday.$anyError
      ) {
        return false;
      }
      return true;
    },
    onClose() {
      this.$emit('input', false);
    },
    async onSavePolicy() {
      if (!this.onCheckValidation()) return;
      const formattedPolicyData = {
        ...this.policy,
        policyId: this.policy.policyId.trim(),
        phone: this.policy.phoneNumber,
      };

      formattedPolicyData.startDate = formattedPolicyData.startDate ? formatISO(formattedPolicyData.startDate) : null;
      formattedPolicyData.birthday = formattedPolicyData.birthday ? formatISO(formattedPolicyData.birthday) : null;
      formattedPolicyData.buyDate = formattedPolicyData.buyDate ? formatISO(formattedPolicyData.buyDate) : null;
      formattedPolicyData.endDate = formattedPolicyData.endDate
        ? formatISO(formattedPolicyData.endDate.setHours(23, 59, 0, 0)) // set time to 23:59
        : null;
      // formattedPolicyData.validities = formattedPolicyData.validities.map(({ startDate, endDate, counter }) => ({
      //   counter,
      //   startDate: startDate ? formatISO(startDate) : null,
      //   endDate: endDate ? formatISO(endDate) : null,
      // }));

      try {
        await this.$store.dispatch(this.$types.PATIENT_POLICY_CREATE, formattedPolicyData);
      } catch (e) {
        console.log(e);
      } finally {
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.button-remove-policy {
  width: 170px;
}
.policies-form-fields {
  margin-bottom: 30px;
}

.crm-form-block {
  width: 100%;
}

.crm-form-field{
  &.w-50 {
    width: calc(50% - 15px);
  }
}
.crm-datepicker {
  line-height: 0 !important;
}

.phone-container-text {
  transition: all 1s;
  text-align: center;
  font-size: small;
  color: red;
  padding-top: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .7s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
